import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons/faPlane';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faChild } from '@fortawesome/free-solid-svg-icons/faChild';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faChild as faChildSolid } from '@fortawesome/free-solid-svg-icons/faChild';
//import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faPoundSign } from '@fortawesome/free-solid-svg-icons/faPoundSign';
import CustomTable from './CustomTable'; // Import CustomTable component
import { faHistory, faImage, faStar } from '@fortawesome/free-solid-svg-icons';
//import Sparkline from 'react-sparklines'; // Import react-sparklines component


const ShowResultsPage = () => {
  const { searchname } = useParams();
  const [searchResults, setSearchResults] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!searchname) {
          throw new Error('searchname is null');
        }
  
        const apiUrl = `/api/showResultsCard/${searchname}`;
        const response = await axios.get(apiUrl);
  
        if (!response || !response.data || !Array.isArray(response.data)) {
          throw new Error('Invalid response format');
        }
  
        // Unwrap the json_output object from the response
        const unwrappedResults = response.data.map(item => item.json_output);
  
        setSearchResults(unwrappedResults);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [searchname]);



  const columns = useMemo(
    () => [
      {
        Header: <><FontAwesomeIcon icon={faBuilding} /> </>,
        accessor: 'hotelname',
	      canSort: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faPlane} /> Airline</>,
        accessor: 'airline',
        shouldHide: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faMapMarkerAlt} /> Airport</>,
        accessor: 'airport',
	      canSort: false,
        shouldHide: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faClipboardList} /> </>,
        accessor: 'boardname',
	      canSort: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faCalendarAlt} /> Date</>,
        accessor: 'date',
        shouldHide: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faClock} /> Duration</>,
        accessor: 'duration',
        shouldHide: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faChild} /> </>,
        accessor: 'freechildcount',
      },
      {
        Header: <><FontAwesomeIcon icon={faGlobe} /> </>,
        accessor: 'location',
      },
      {
        Header: <><FontAwesomeIcon icon={faUser} /> Room Adults</>,
        accessor: 'roomadults',
        shouldHide: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faChildSolid} /> Room Children</>,
        accessor: 'roomchildren',
        shouldHide: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faPoundSign} /> </>,
        accessor: 'totalprice',
	      canSort: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faHistory} /> Price History</>,
        name: 'price_history',
        accessor: 'price_history',
        canSort: false,
        sparklineOnly: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faImage} /> </>,
        accessor: 'image_url',
        name: 'image_url',
	      canSort: false,
        sparklineOnly: true,
      },
      {
        Header: <><FontAwesomeIcon icon={faStar} /> </>,
        accessor: 'tripAdvisorRatingImage',
        name: 'tripadvisorratingimage',
	      canSort: false,
        sparklineOnly: true,
      },
    ],
    []
  );

  return (
    <div className="main-container mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-4">Search Results for: {searchname}</h1>
      <div style={{ display: 'table' }}>
        <div style={{ display: 'table-row' }}>
          <div style={{ display: 'table-cell', paddingRight: '10px' }}>
            <h4>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                <li>Date:</li>
                <li>Duration:</li>
                <li>Airline:</li>
              </ul>
            </h4>
          </div>
          <div style={{ display: 'table-cell', paddingLeft: '10px', color: 'red'}}>
            <h4>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                <li>{searchResults.length > 0 && searchResults[0].date}</li>
                <li>{searchResults.length > 0 && searchResults[0].duration}</li>
                <li>{searchResults.length > 0 && searchResults[0].airline}</li>
              </ul>
            </h4>
          </div>
          <div style={{ display: 'table-cell', paddingRight: '10px', paddingLeft: '30px' }}>
            <h4>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                <li>Airport:</li>
                <li>Adults:</li>
                <li>Children:</li>
              </ul>
            </h4>
          </div>
          <div style={{ display: 'table-cell', paddingLeft: '10px', color: 'red'}}>
            <h4>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                <li>{searchResults.length > 0 && searchResults[0].airport}</li>
                <li>{searchResults.length > 0 && searchResults[0].roomadults}</li>
                <li>{searchResults.length > 0 && searchResults[0].roomchildren}</li>
              </ul>
            </h4>
          </div>
        </div>
    </div>


      <CustomTable columns={columns} data={searchResults} />
    </div>
  );
};

export default ShowResultsPage;
