import React from 'react';

const HomePage = () => {
  return (
<div class="container mx-auto mt-10">
  <h1 class="text-3xl font-bold mb-4">Discover Your Perfect Getaway with Our Price Tracking and Package Holiday Search Platform</h1>
  <p class="text-lg mb-6">
    Are you tired of spending hours scouring the internet for the best holiday deals? Look no further! Our platform is your ultimate travel companion, offering a seamless experience for price tracking, real-time alerts, and hassle-free package holiday searches. Let's dive into what makes us your go-to destination for unforgettable vacations:
  </p>
  <ul class="list-disc pl-6 mb-6">
    <li>
      <strong>Price Tracking Made Effortless</strong>
      <ul class="list-disc pl-6">
        <li>Real-Time Alerts: Receive instant notifications when prices drop for your dream destinations.</li>
        <li>Compare and Save: Our intuitive interface lets you compare prices across multiple providers.</li>
      </ul>
    </li>
    <li>
      <strong>Package Holidays Tailored to You</strong>
      <ul class="list-disc pl-6">
        <li>All-Inclusive Bliss: Picture yourself sipping cocktails by the pool, knowing that everything – from flights to accommodation – is taken care of.</li>
        <li>Family-Friendly Escapes: Traveling with kids? We've got family-friendly options that cater to every age group.</li>
        <li>Romantic Retreats: Surprise your partner with a romantic getaway. Candlelit dinners, sunset strolls, and luxurious accommodations – our packages set the stage for unforgettable moments.</li>
      </ul>
    </li>
    <li>
      <strong>Why Choose Us?</strong>
      <ul class="list-disc pl-6">
        <li>Comprehensive Comparison: We bring together thousands of cheap package holidays from leading UK travel companies.</li>
        <li>Time and Money Savings: No more endless browsing. Our platform streamlines the process.</li>
        <li>Expert Advice: Need travel tips? We've got you covered.</li>
      </ul>
    </li>
  </ul>
  <p class="text-lg">
    Your dream holiday awaits! Explore our site, track prices, and discover the perfect package. Whether you're a sunseeker, culture enthusiast, or adventure lover, we've got the ideal getaway just a click away. Bon voyage! 🌴🌍🌞
  </p>
  <p class="text-sm text-gray-600">
    *Prices accurate as of 3am on April 24, 2024. Deals are subject to our partners' terms and conditions.*
  </p>
  <p class="mt-4">
    Start today <a href="https://holiday.server.cranie.com/results" class="text-blue-500 hover:underline">our website</a> to start your journey today! 🚀✈️
  </p>
</div>

  );
};

export default HomePage;
