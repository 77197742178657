import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './CustomTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt, faMapMarkerAlt, faChild, faBuilding, faGlobe, faUser } from '@fortawesome/free-solid-svg-icons';

const ResultsPage = ({ match }) => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl = '/api/showAlerts';
        if (match && match.params && match.params.name) {
          apiUrl += `?name=${match.params.name}`;
        }
        const response = await axios.get(apiUrl);
        setAlerts(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [match]);

  return (
    <div className="main-container">
      <div className="container">
        {alerts.length > 0 ? (
          alerts.map((alert, index) => (
            <div key={index} className="card" style={{backgroundColor: '#f4f4f4'}}>
              <div style={{marginBottom: '1.5em', display: 'flex', alignItems: 'center', gap: '1em'}}>
                <Link to={`/showResults/${alert.searchname}`} className="card-title">
                  {alert.searchname}
                </Link>
              </div>
              <div className="card-body">
                <div className="card-text">
                  <FontAwesomeIcon icon={faCalendarAlt} className="card-icon" />
                  <div style={{marginLeft: '1em'}} className="card-value">{alert.date}</div>
                </div>
                <div className="card-text">
                  <FontAwesomeIcon icon={faClock} className="card-icon" />
                  <div style={{marginLeft: '1em'}} className="card-value">{alert.duration}</div>
                </div>
                <div className="card-text">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="card-icon" />
                  <div style={{marginLeft: '1em'}} className="card-value">{alert.airport}</div>
                </div>
                <div className="card-text">
                  <FontAwesomeIcon icon={faGlobe} className="card-icon" />
                  <div style={{marginLeft: '1em'}} className="card-value">{alert.location}</div>
                </div>
                <div className="card-text">
                  <FontAwesomeIcon icon={faUser} className="card-icon" />
                  <div style={{marginLeft: '1em'}} className="card-value">{alert.roomadults}</div>
                </div>
                <div className="card-text">
                  <FontAwesomeIcon icon={faChild} className="card-icon" />
                  <div style={{marginLeft: '1em'}} className="card-value">{alert.roomchildren}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No alerts found.</p>
        )}
      </div>
    </div>
  );
};

export default ResultsPage;


