import React from 'react';

const AboutPage = () => {
  return (
    <div className="container mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="text-lg mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.
        Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at
        nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec
        tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget
        nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra,
        per inceptos himenaeos.
      </p>
      <p className="text-lg">
        Curabitur sodales ligula in libero. Sed dignissim lacinia nunc. Curabitur
        tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas
        mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor.
        Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac
        turpis quis ligula lacinia aliquet. Mauris ipsum. Nulla metus metus, ullamcorper
        vel, tincidunt sed, euismod in, nibh. Quisque volutpat condimentum velit.
      </p>
    </div>
  );
};

export default AboutPage;