import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import CreateAlertPage from './components/CreateAlertPage';
import ResultsPage from './components/ResultsPage';
import AboutPage from './components/AboutPage';
import ShowResultsPage from './components/ShowResultsPage'; // Import the new component
import logo from './logo.jpg' ;


function App() {
  useEffect(() => {
    // Hide the address bar on mobile devices
    window.addEventListener('load', () => {
      setTimeout(() => {
        window.scrollTo(0, 1);
      }, 0);
    });
  }, []);
  
  return (
    <Router>
      <div>
        <header style={{display: 'flex', alignItems: 'center'}}>
          <img src={logo} alt="logo" style={{height: 50, width: 50}}/>
          <h1 style={{marginLeft: 10}}>Holiday Tracker</h1>
        </header>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/create-alert" element={<CreateAlertPage />} />
          <Route path="/results/:name?" element={<ResultsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/showResults/:searchname" element={<ShowResultsPage />} /> {/* Add this route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
