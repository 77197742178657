// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form label {
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .form input,
  .form select,
  .form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .form input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }
  
  .form button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form button:hover {
    background-color: #0056b3;
  }
`, "",{"version":3,"sources":["webpack://./src/components/CreateAlertPage.css"],"names":[],"mappings":";;;;AAIA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uCAAuC;EACzC;;EAEA;IACE,cAAc;IACd,eAAe;IACf,mBAAmB;EACrB;;EAEA;;;IAGE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["\n\n\n\n.container {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .title {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n  .form {\n    background-color: #f9f9f9;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .form label {\n    display: block;\n    font-size: 18px;\n    margin-bottom: 10px;\n  }\n  \n  .form input,\n  .form select,\n  .form textarea {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    margin-bottom: 20px;\n    font-size: 16px;\n  }\n  \n  .form input[type=\"checkbox\"] {\n    width: auto;\n    margin-right: 10px;\n  }\n  \n  .form button {\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    font-size: 18px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .form button:hover {\n    background-color: #0056b3;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
