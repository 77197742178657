// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Your existing styles */
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #0d1280; /* Dark deep navy color */
  color: white;
  height: 40px;
}

.nav-item .icon {
  display: none; /* Hide icons on larger screens */
  color: white; /* Set icon color to white */
}

.nav-item .text {
  display: block; /* Show text on larger screens */
  color: white; /* Set icon color to white */
}

@media (max-width: 768px) {
  .navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1; /* Add this line to adjust stacking order */
  }

  .nav-item .icon {
    display: block; /* Show icons on smaller screens */
    font-size: 24px; /* Adjust the size as needed */
    margin-bottom: 10px; /* This adds a gap at the bottom */
    margin-top: 10px;
  }

  .nav-item .text {
    display: none; /* Hide text on smaller screens */
  }

  .container-page {
    margin-top: 20px; /* Add margin to push content below the navbar */
    margin-bottom: 50px; /* Add margin to prevent content from going behind the navbar at the bottom */
    min-height: calc(100vh - 100px); /* Adjust the height to account for the navbar and bottom margin */
    overflow: auto;
  }



}


`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,yBAAyB,EAAE,yBAAyB;EACpD,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa,EAAE,iCAAiC;EAChD,YAAY,EAAE,4BAA4B;AAC5C;;AAEA;EACE,cAAc,EAAE,gCAAgC;EAChD,YAAY,EAAE,4BAA4B;AAC5C;;AAEA;EACE;IACE,eAAe;IACf,SAAS;IACT,WAAW;IACX,UAAU,EAAE,2CAA2C;EACzD;;EAEA;IACE,cAAc,EAAE,kCAAkC;IAClD,eAAe,EAAE,8BAA8B;IAC/C,mBAAmB,EAAE,kCAAkC;IACvD,gBAAgB;EAClB;;EAEA;IACE,aAAa,EAAE,iCAAiC;EAClD;;EAEA;IACE,gBAAgB,EAAE,gDAAgD;IAClE,mBAAmB,EAAE,6EAA6E;IAClG,+BAA+B,EAAE,kEAAkE;IACnG,cAAc;EAChB;;;;AAIF","sourcesContent":["/* Your existing styles */\n.navbar {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  background-color: #0d1280; /* Dark deep navy color */\n  color: white;\n  height: 40px;\n}\n\n.nav-item .icon {\n  display: none; /* Hide icons on larger screens */\n  color: white; /* Set icon color to white */\n}\n\n.nav-item .text {\n  display: block; /* Show text on larger screens */\n  color: white; /* Set icon color to white */\n}\n\n@media (max-width: 768px) {\n  .navbar {\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    z-index: 1; /* Add this line to adjust stacking order */\n  }\n\n  .nav-item .icon {\n    display: block; /* Show icons on smaller screens */\n    font-size: 24px; /* Adjust the size as needed */\n    margin-bottom: 10px; /* This adds a gap at the bottom */\n    margin-top: 10px;\n  }\n\n  .nav-item .text {\n    display: none; /* Hide text on smaller screens */\n  }\n\n  .container-page {\n    margin-top: 20px; /* Add margin to push content below the navbar */\n    margin-bottom: 50px; /* Add margin to prevent content from going behind the navbar at the bottom */\n    min-height: calc(100vh - 100px); /* Adjust the height to account for the navbar and bottom margin */\n    overflow: auto;\n  }\n\n\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
