import React from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons/faSort';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { Sparklines, SparklinesLine, SparklinesSpots, SparklinesCurve, SparklinesReferenceLine } from 'react-sparklines';

import { faCocktail } from '@fortawesome/free-solid-svg-icons/faCocktail';
import { faGlassCheers } from '@fortawesome/free-solid-svg-icons/faGlassCheers';
import { faBed } from '@fortawesome/free-solid-svg-icons/faBed';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { faWineGlass } from '@fortawesome/free-solid-svg-icons/faWineGlass';
import { faCoffee } from '@fortawesome/free-solid-svg-icons/faCoffee';

import './CustomTable.css';


const CustomTable = ({ columns, data }) => {
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, sortBy: [{ id: 'totalprice' }] },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );



  const generateSparkline = (priceHistory) => {
    // Trim leading spaces from each string and convert them to numbers
    //const cleanedPriceHistory = priceHistory.map(price => parseFloat(price.trim()));
    const cleanedPriceHistory = priceHistory;
    //console.log('Price History:', priceHistory); // Debugging statement to log the price history data
    //console.log('Price History Clean:', cleanedPriceHistory); // Debugging statement to log the price history data
    return (
      <div className='sparkline'>
        <Sparklines data={cleanedPriceHistory} width={150} height={50} margin={10}  >
          <SparklinesCurve style={{ strokeWidth: 3, stroke: "#2e8540", fill: "none" }} />
          <SparklinesSpots />
        </Sparklines>
      </div>
    );
  };

  const { globalFilter } = state;

  const onChangeFilter = (e) => {
    setGlobalFilter(e.target.value || undefined);
  };

  const renderFilterIcon = (column) => {
    if (column.canSort) {
      if (column.isSorted) {
        return column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faFilter} />;
      }
    }
    return null;
  };

  return (
    <div className='main-container'>
      <input
        type="text"
        value={globalFilter || ''}
        onChange={onChangeFilter}
        placeholder="Search..."
      />
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>
        <span>
          Page <strong>{state.pageIndex + 1} of {pageOptions.length}</strong>
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
        <select
          value={state.pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>









<div className="container">
      {page.map((row, i) => {
        prepareRow(row);
        return (
          <div key={i} className="card">
            <a href="#" className="thumbnail">
              <img src={row.original.image_url || "https://placehold.co/600x400/svg?text=Hotel%20Picture%20TBD"} alt={row.original.alt_text || 'Placeholder Image'} className="main-image" />
            </a>
            <img src={row.original.tripAdvisorRatingImage || "https://placehold.co/600x400/svg?text=%20"} alt={row.original.alt_text || 'Placeholder Image'} className="sub-image" />
            <div className="card-title">
              <h2>{row.original.hotelname}</h2>
              <p>{row.original.location}</p>
            </div>
            
            <div className="card-body">
              {Object.entries(row.original.Details).map(([boardName, boardDetails], j) => (
                <div key={j} className="card-text">
                  <div className="card-label">
                    {boardName === 'All Inclusive' && <FontAwesomeIcon icon={faGlassCheers} title="All Inclusive" />}
                    {boardName === 'Bed & Breakfast' && <FontAwesomeIcon icon={faBed} title="Bed & Breakfast" />}
                    {boardName === 'Full Board' && <FontAwesomeIcon icon={faUtensils} title="Full Board" />}
                    {boardName === 'Full Board' && <FontAwesomeIcon icon={faWineGlass} title="Full Board" />}
                    {boardName === 'Half Board' && <FontAwesomeIcon icon={faCoffee} title="Half Board" />}
                    {boardName === 'Half Board' && <FontAwesomeIcon icon={faUtensils} title="Half Board" />}
                    {boardName === 'Self Catering' && <FontAwesomeIcon icon={faUtensils} title="Self Catering" />}
                    {boardName === 'Room Only' && <FontAwesomeIcon icon={faBed} title="Room Only" />}
                  </div>
                  <div className="card-value">£{boardDetails.total_price}</div>
                  <div className="sparkline-container">
                    <div className="sparkline-item">
                      {generateSparkline(boardDetails.price_history)}
                    </div>
                  </div>
                </div>
              ))}
              
              <a href="#" className="border-t border-grey-light pt-2 text-xs text-grey hover:text-red uppercase no-underline tracking-wide">View Holiday</a>
            </div>
          </div>
        );
      })}
</div>









      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>
        <span>
          Page <strong>{state.pageIndex + 1} of {pageOptions.length}</strong>
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
        <select
          value={state.pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomTable;
