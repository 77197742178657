import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CreateAlertPage.css'; // Import CSS file for styling

const CreateAlertPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    dates: null,
    numOfAdults: 1,
    numOfChildren: 0,
    childAges: ['', ''],
    duration: [],
    airports: [],
    destinations: [],
    boardBasis: [],
    alertPrice: '',
    freeChildPlace: false,
  });

  const debugMode = false ;

  const handleDateFieldFocus = (e) => {
    e.target.blur(); // Blur the input field to prevent keyboard from appearing
  };

  const [selectedOptions, setSelectedOptions] = useState('');
  const [airportOptions, setAirportOptions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [boardOptions, setBoardOptions] = useState([]);

  useEffect(() => {
    const jsonOptions = JSON.stringify(formData, null, 2);
    setSelectedOptions(jsonOptions);
  }, [formData]);

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await axios.get('/api/airport');
        setAirportOptions(response.data);
      } catch (error) {
        console.error('Error fetching airports:', error);
      }
    };

    const fetchDestinations = async () => {
      try {
        const response = await axios.get('/api/destination');
        setDestinationOptions(response.data);
      } catch (error) {
        console.error('Error fetching destinations:', error);
      }
    };

    const fetchBoardBasis = async () => {
      try {
        const response = await axios.get('/api/board');
        setBoardOptions(response.data);
      } catch (error) {
        console.error('Error fetching board basis:', error);
      }
    };

    fetchAirports();
    fetchDestinations();
    fetchBoardBasis();
  }, []);
  
  
  const handleChange = (e) => {
    const { name, value, type, checked, options } = e.target;
    let newValue = value;
    if (type === 'checkbox') {
      newValue = checked;
    } else if (type === 'select-multiple') {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    }
    if (name === 'numOfChildren' && formData.numOfChildren !== parseInt(newValue)) {
      // If the number of children changes, reset child ages
      setFormData({ ...formData, [name]: parseInt(newValue), childAges: Array(parseInt(newValue)).fill(0) });
    } else {
      setFormData({ ...formData, [name]: newValue });
    }
  };

  /* useEffect(() => {
    const jsonOptions = JSON.stringify(formData, null, 2);
    setSelectedOptions(jsonOptions);
  }, [formData]); */

  /* const handleDateChange = (index, value) => {
    const newDates = [...formData.dates];
    newDates[index] = value;
    setFormData({ ...formData, dates: newDates });
  }; */

  const handleDateChange = (dates) => {
    setFormData({ ...formData, dates });
  };


  const handleChildAgeChange = (index, value) => {
    const newAges = [...formData.childAges];
    newAges[index] = value;
    setFormData({ ...formData, childAges: newAges });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send form data to your backend
      await axios.post('/api/create-alert', formData);
      // Optionally, you can redirect the user to another page after successful submission
      // history.push('/success');
    } catch (error) {
      console.error('Error creating alert:', error);
    }
  };

  return (
    <div className="container" >
      <h1 className="title" >Create Alert</h1>
      <form onSubmit={handleSubmit} className="form">
        <div className="mb-4">
          <label htmlFor="email" className="block text-lg font-medium">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
          />
        </div>
        {/* {[...Array(3)].map((_, index) => (
          <div className="mb-4" key={index}>
            <label htmlFor={`date${index}`} className="block text-lg font-medium">Date {index + 1}:</label>
            <input
              type="date"
              id={`date${index}`}
              name={`date${index}`}
              value={formData.dates[index]}
              onChange={(e) => handleDateChange(index, e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
        ))} */}

        <div className="mb-4">
          <label htmlFor="dates" className="block text-lg font-medium">Dates:</label>
          <DatePicker
            showIcon
            id="dates"
            name="dates"
            selectedDates={formData.dates}
            onChange={handleDateChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
            dateFormat="yyyy-MM-dd"
            placeholderText="       Select multiple dates"
            selectsMultiple={true}
            selectsMultiple
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
            onFocus={handleDateFieldFocus} // Trigger blur() on focus
            //inputmode="none"
          />
        </div>



        <div className="mb-4">
          <label htmlFor="numOfAdults" className="block text-lg font-medium">Number of Adults:</label>
          <select
            id="numOfAdults"
            name="numOfAdults"
            value={formData.numOfAdults}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
          </select>
        </div>




        <div className="mb-4">
          <label htmlFor="numOfChildren" className="block text-lg font-medium">Number of Children:</label>
          <select
            id="numOfChildren"
            name="numOfChildren"
            value={formData.numOfChildren}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
          >
            {[0, 1, 2].map((num) => (
              <option key={num} value={num}>{num}</option>
            ))}
          </select>
        </div>
        {formData.numOfChildren > 0 && (
          <div>
            {[...Array(formData.numOfChildren)].map((_, index) => (
              <div className="mb-4" key={index}>
                <label htmlFor={`childAge${index}`} className="block text-lg font-medium">Child {index + 1} Age:</label>
                <input
                  type="number"
                  id={`childAge${index}`}
                  name={`childAge${index}`}
                  value={formData.childAges[index]}
                  onChange={(e) => handleChildAgeChange(index, e.target.value)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
            ))}
          </div>
        )}






        <div className="mb-4">
          <label htmlFor="duration" className="block text-lg font-medium">Duration:</label>
          <select
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
            multiple
          >
            <option value={7}>7</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={14}>14</option>
          </select>
        </div>
        

        <div className="mb-4">
          <label htmlFor="airports" className="block text-lg font-medium">Airports:</label>
          <select
            id="airports"
            name="airports"
            value={formData.airports}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
            multiple
          >
            {airportOptions.map((airport) => (
              <option key={airport.airportid} value={airport.name}>
                {airport.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="destinations" className="block text-lg font-medium">Destinations:</label>
          <select
            id="destinations"
            name="destinations"
            value={formData.destinations}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
            multiple
          >
            {destinationOptions.map((destination) => (
              <option key={destination.resortid} value={destination.name}>
                {destination.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
        <p className="text-sm text-gray-500">
            When selected, only alerts will be sent when board and price are matched.
          </p>
          <label htmlFor="boardBasis" className="block text-lg font-medium">Board Basis:</label>
          <select
            id="boardBasis"
            name="boardBasis"
            value={formData.boardBasis}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            required
            multiple
          >
            {boardOptions.map((board) => (
              <option key={board.boardid} value={board.name}>
                {board.name}
              </option>
            ))}
          </select>
        </div>



        <div className="mb-4">
          <label htmlFor="alertPrice" className="block text-lg font-medium">Alert Price:</label>
          <input
            type="range"
            id="alertPrice"
            name="alertPrice"
            min={500}
            max={4000}
            step={50}
            value={formData.alertPrice}
            onChange={handleChange}
            className="mt-1 w-full"
            required
          />
          <span className="text-sm text-gray-500">£ {formData.alertPrice}</span>
        </div>


        <div className="mb-4">
          <p className="text-sm text-gray-500">
            Alerts on ANY change to a new free child place.
          </p>
          <label htmlFor="freeChildPlace" className="block text-lg font-medium">Free Child Place Alert:</label>
          <input
            type="checkbox"
            id="freeChildPlace"
            name="freeChildPlace"
            checked={formData.freeChildPlace}
            onChange={handleChange}
            className="mt-1 border border-gray-300 rounded-md"
          />
        </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Submit
          </button>
        </form>
        {debugMode && (  // Assuming debugMode is a boolean state variable controlling debug visibility
          <div className="mt-8">
            <h2 className="text-lg font-bold mb-2">Selected Options:</h2>
            <textarea
              rows="10"
              readOnly
              className="border border-gray-300 rounded-md w-full"
              value={selectedOptions}
            />
          </div>
        )}
      </div>
    );
  };
  
  export default CreateAlertPage;