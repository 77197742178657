import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faPlus, faChartBar } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="nav-item">
        <FontAwesomeIcon icon={faHome} className="icon" />
        <span className="text">Home</span>
      </Link>
      <Link to="/create-alert" className="nav-item">
        <FontAwesomeIcon icon={faPlus} className="icon" />
        <span className="text">Create</span>
      </Link>
      <Link to="/results" className="nav-item">
        <FontAwesomeIcon icon={faChartBar} className="icon" />
        <span className="text">Results</span>
      </Link>
      <Link to="/about" className="nav-item">
        <FontAwesomeIcon icon={faUser} className="icon" />
        <span className="text">About</span>
      </Link>
    </nav>
  );
};

export default Navbar;

